<template>
  <div class="page-content" v-if="changguan">
    <div class="changguan-details-header">
      <div class="changguan-details-header-left">
        <div>
<!--          <img class="changguan-details-header-logo" :src="changguan.data.image_list&&changguan.data.image_list.length>0?changguan.data.image_list[0]:''">-->
          <el-image class="changguan-details-header-logo" fit="fill" :src="changguan.data.image_list&&changguan.data.image_list.length>0?changguan.data.image_list[0]:''" lazy>
            <div slot="error" class="image-slot">
              <img :src="require('../assets/images/no_logo.png')" alt="" style="width: 247px;height: 200px;" />
            </div>
          </el-image>
        </div>
      </div>
      <div class="changguan-details-header-right">
        <div class="changguan-details-title">
          {{changguan.data.name}}
        </div>
        <el-row>
          <el-col :span="14">
            <div class="changguan-details-fields">
              <div class="info-field" style="width: 50%">
                <span class="info-field-label">酒店地址：</span>
                <span class="info-field-value">{{changguan.data.address}}</span>
              </div>
<!--              <div class="info-field" style="width: 50%">
                <span class="info-field-label">开业时间：</span>
                <span class="info-field-value">{{changguan.data.opening_date}}</span>
              </div>-->
              <div class="info-field" style="width: 50%">
                <span class="info-field-label">最近装修时间：</span>
                <span class="info-field-value">{{changguan.data.redecorate_date}}</span>
              </div>
              <div class="info-field" style="width: 50%">
                <span class="info-field-label">会场数量：</span>
                <span class="info-field-value">{{changguan.data.conf_total}}间</span>
              </div>
              <div class="info-field" style="width: 50%">
                <span class="info-field-label">客房数量：</span>
                <span class="info-field-value">{{changguan.data.room_total}}间</span>
              </div>
              <div class="info-field" style="width: 50%">
                <span class="info-field-label">容纳人数：</span>
                <span class="info-field-value">{{changguan.data.conf_min_capacity}}-{{changguan.data.conf_max_capacity}}人</span>
              </div>
              <div class="info-field" style="width: 50%">
                <span class="info-field-label">会场价格：</span>
                <span class="info-field-value">￥{{changguan.data.conf_min_price>0?changguan.data.conf_min_price:'-'}}</span>
              </div>
              <div class="info-field" style="width: 50%">
                <span class="info-field-label">客房参考价：</span>
                <span class="info-field-value">￥{{changguan.data.room_price>0?changguan.data.room_price:'-'}}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-row :gutter="30">
      <el-col :span="14">
        <block title="酒店简介">
          <div v-html="richTextToHtml(changguan.data.intro)" style="height: 360px;overflow: auto;"></div>
        </block>
      </el-col>
      <el-col :span="10">
        <block title="举办会议">
          <div class="info-list">
            <div v-for="item in changguan.data.history_active" :key="item" class="info-list-item">
              <div class="info-list-item-dot"></div>
              <div class="info-list-field">
                {{item}}
              </div>
            </div>
          </div>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <hxe-changguan-ddtc-block :changguan="changguan"></hxe-changguan-ddtc-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <hxe-nearest-changguan-zhanhui-info-block :changguan="changguan"></hxe-nearest-changguan-zhanhui-info-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <hxe-nearest-hotel-list-block :changguan="changguan"></hxe-nearest-hotel-list-block>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import api from "../api";
import HtmlUtils from "../utils/HtmlUtils";
import Block from "./Block";
import HxeChangguanDdtcBlock from "./HxeChangguanDdtcBlock";
import HxeNearestChangguanZhanhuiInfoBlock from "./HxeNearestChangguanZhanhuiInfoBlock";
import HxeNearestHotelListBlock from "./HxeNearestHotelListBlock";
export default {
  name: "HxeChangguanDetails",
  components:{
    Block,
    HxeChangguanDdtcBlock,
    HxeNearestChangguanZhanhuiInfoBlock,
    HxeNearestHotelListBlock
  },
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      changguan:null,
    }
  },
  created() {
    this.init()
  },
  computed:{
  },
  methods: {
    async init(){
      this.changguan=(await api.getHuixiaoerChangguanById(this.$route.query.id)).data
    },
    richTextToHtml(str){
      return HtmlUtils.richTextToHtml(str)
    }
  }
}
</script>

<style scoped>

</style>
